/* You can add global styles to this file, and also import other style files */
@import "scss/variable_overrides";
@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "scss/calendar.css";

.primary {
    color: #1e890d;
}

.secondary {
    color: #ff7900;
}

.light {
    color: #C2C2C2;
}

.dark {
    color: black;
}

.white {
    color: white;
}

html {
    background-color: red;
}

html,
body {
    height: 100%;    
    color: white;
    background-color: transparent;
}

.app-background {
    position: fixed;
    z-index: -50;
    height: 100vh;
    width: 100vw;
    background: linear-gradient(45deg, #6ace98 50%, #f7933f 50%);
}

.btn {
    color: white;
    background-color: #ff7900;
    border-radius: 5rem;
    box-shadow: 0 0 5px white;
}

.btn:hover {
    color: #F7933F;
    background-color: white;
    box-shadow: 0 0 5px #F7933F;
}

.row {
    --bs-gutter-x: 0;
}

.question {
    max-width: none !important;
}

.question .mat-dialog-container {
    background-color: #6ace98;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0;
}

.card .ref {
    height: 140px;
}


.mat-dialog-container {
    // background-color: wheat;
    background: rgb(106, 206, 152);
    // background: linear-gradient(180deg, rgba(24, 73, 31, 1) 0%, rgba(66, 221, 89, 1) 92%);
}