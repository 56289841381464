// // Colors
$primary:       #42dd59;
$secondary:       #F7933F;
$light:         #C2C2C2;
$dark:          black;

// // Headings style
$headings-font-family: 'Righteous', 'Impact', 'Times New Roman', Times, serif;
$headings-color: $primary;
$font-weight-headings: 6rem;
// $h2-font-size: 3rem;

// // Base style
$font-family-base: 'Righteous', sans-serif;
$font-weight-base: 400;

// // Search Bar & Newsletter Input
// $input-focus-border-color:          $primary;
// $input-focus-box-shadow:            0 0 5px $primary !important;

// // links
$link-color:                        white;
$link-decoration:                   none;

// // cards
// $card-border-color:                 none;
// $card-border-radius:                none;

// buttons
$btn-border-radius:                 none;